import React from "react";
import {BotonRegistrar, BotonCerrarCajaLetras} from "../Plantillas/Botones";
import ListMovimientos from "../movimientosCaja/ListaMovimientosPorTurno";
import Modal from "../Modal";
import {notificarError} from "../Almacenes/AlmacenNotify";
import TurnoCierre from "./TurnoCierre";
import _ from 'lodash';
import {registroMovCajaPDF} from "./printDataCaja";
import { BotonLetrasVerdeOscuro } from "../Plantillas/Botones"

export default class MovimientosCaja extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            movs: [],
            isLoading: false,
            doReload: false,
            TipoMovimiento: 'Entrada',
            Monto: 0,
            Observacion: '',
            modalIsOpen: false,
            modalIsOpenCierre: false,
            mostrarTipoMovimiento: false,
            buttonAddMov: false,
            cierreCompletado: false,
        }
        this.handleCloseModalCierre = this.handleCloseModalCierre.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.handleChangeTipoMovimiento = this.handleChangeTipoMovimiento.bind(this);
        this.handleClickNuevoCierre = this.handleClickNuevoCierre.bind(this);
        this.handleCierreTurnoCompletado = this.handleCierreTurnoCompletado.bind(this);
        this.handleRefreshPage = this.handleRefreshPage.bind(this);
    }

    async componentDidMount() {
        this.setState({isLoading: true})
        await this.obtenerMovimientos(this.state);
        this.setState({isLoading: false})
    }

    async obtenerMovimientos() {
        try {
            const response = await fetch(`/api/cajas/`);
            const data = await response.json();
            this.setState({movs: data.movCajas})
        } catch (error) {
            notificarError("No se pudieron cargar los movimientos de caja.")
        }
    }

    async onSubmitHandler(e) {
        e.preventDefault()
        this.setState({buttonAddMov: true})
        this.setState({isLoading: true})
        await this.addMovimientoCaja()
        await this.obtenerMovimientos();
        const data = {idTurno: this.props.idTurno, data: {...this.state}}
        await registroMovCajaPDF(data);
        this.setState({Observacion: ''})
        this.setState({Monto: 0})
        this.setState({isLoading: false})
        this.setState({buttonAddMov: false})
    }

    async addMovimientoCaja() {
        try {
            const res = await fetch(`/api/cajas/addMov`, {
                method: 'POST',
                body: JSON.stringify({
                    TipoMovimiento: this.state.TipoMovimiento,
                    Monto: this.state.Monto,
                    Saldo: 0,
                    MontoFinalEfectivoSistema: 0,
                    Observacion: this.state.Observacion
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
        } catch (err) {
            notificarError('No se pudo agregar el movimiento de caja.')
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: e.target.getAttribute('type') === 'number' ? (_.isNumber(Number(value)) ? value : 0) : value
        })
    }

    handleCloseModalCierre() {
        this.setState({modalIsOpenCierre: false})
    }


    handleChangeTipoMovimiento(e) {
        this.setState({
            TipoMovimiento: e.target.value
        })
    }

    getBotonTipoComprobante(tipoMovimiento, NombreDocSunat) {
        return (
            <>
                <label htmlFor="TipoMovimiento">Tipo de Movimiento</label>
                <select
                    id="TipoMovimiento"
                    name="TipoMovimiento"
                    className="form-control"
                    value={this.state.TipoMovimiento}
                    onChange={this.handleChangeTipoMovimiento}
                >
                    <option value="Entrada" selected={this.state.TipoMovimiento === 'Entrada'} className="select-search-box__options">Entrada</option>
                    <option value="Salida" selected={this.state.TipoMovimiento === 'Salida'} className="select-search-box__options">Salida</option>
                </select>
            </>
        );
    }

    handleClickNuevoCierre(e) {
        this.setState({
            modalIsOpenCierre: true
        })
    }

    handleClickTipoMovimiento = () => {
        this.setState({ mostrarTipoMovimiento: true });
    }

    handleCierreTurnoCompletado = () => {
        this.setState({ modalIsOpenCierre: false, cierreCompletado: true });
    }

    handleRefreshPage = () => {
        window.location.reload();
    }

    render() {
        const {extImg, idTurno, idCaja} = this.props;

        if (this.state.cierreCompletado) {
            return <section className="ventas-fpay-section-vh cienvh">
                <div className="preventa__container2">
                    <div className="container mt-5">
                        <div className="encabezado">
                            <div className='mt-5 mb-2 instruccion-caja text-center' style={{textAlign: 'justify'}}>
                                Se ha completado exitosamente el cierre de caja.
                            </div>

                            <BotonLetrasVerdeOscuro
                                className="mt-5 d-flex justify-content-center mt-2 aperturar-caja ml-auto mr-auto"
                                onClick={this.handleRefreshPage}>
                                Recargar página
                            </BotonLetrasVerdeOscuro>
                        </div>
                    </div>
                </div>
            </section>
        }

        return (
            <section className="ventas-fpay-section-vh cienvh">
                <div className="preventa__container2">
                    <div className="container mt-5">
                        <div className="encabezado">
                            <BotonCerrarCajaLetras
                                    className="mt-1 w-100 borde-cerrar-caja" onClick={this.handleClickNuevoCierre}
                                    title="Cerrar Caja"/>
                            <div>
                                {!this.state.mostrarTipoMovimiento && (
                                    <button className="registrar-movimiento mt-3 mb-3" onClick={this.handleClickTipoMovimiento}>Seleccione el tipo de movimineto</button>
                                )}
                                {this.state.mostrarTipoMovimiento && (
                                    <div className="preventa__tipo--comprobante">
                                        <div className="mt-2 caja-fondo-blanco-m-2">
                                            {this.getBotonTipoComprobante("Entrada", "Entrada")}
                                            
                                            <hr />

                                            <form onSubmit={this.onSubmitHandler} className="d-flex justify-content-center w-100">
                                                <div className="almacenDiv w-100">
                                                        <b className="">Monto:</b>
                                                        <div className="input__Almacen">
                                                            <input className="input__linea pl-2" name="Monto"
                                                                onChange={this.handleChange}
                                                                onFocus={(e) => e.target.select()}
                                                                type="number" step="any" min="0.01"
                                                                value={this.state.Monto}>
                                                            </input>
                                                        </div>
                                                        <div className="input__Almacen mt-2">
                                                            <b className="">Observación:</b>
                                                            <div className="input__Almacen">
                                                                <input className="input__linea pl-2" onChange={this.handleChange}
                                                                    name="Observacion"
                                                                    value={this.state.Observacion} />
                                                            </div>
                                                            <div align="right" className="pt-3">
                                                                <BotonRegistrar className="mt-2 mb-2"  disabled={this.state.buttonAddMov} />
                                                            </div>
                                                        </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}

                            </div>

                            {this.state.mostrarTipoMovimiento && (
                            <div className="preventa__tipo--comprobante">
                                
                            </div>
                            )}

                            <div className="d-flex justify-content-center w-100">
                                    {this.state.isLoading ? 'Cargando' : <ListMovimientos movs={this.state.movs} idTurno={this.props.idTurno}/>}
                                </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.modalIsOpenCierre} onClose={this.handleCloseModalCierre}
                    title="CERRAR CAJA"
                >
                    <TurnoCierre extImg={extImg} idTurno={idTurno} idCaja={idCaja}
                                 movsCaja={this.state.movs} montoInicial={this.props.montoInicial} onCierreTurnoCompletado={this.handleCierreTurnoCompletado}/>
                </Modal>
            </section>
        )
    }
}
