import React from 'react';
import Notify from '../Notify.js';
import Moment from 'react-moment';
import {BotonCancelar2, BotonLetras} from "../Plantillas/Botones";
import "../styles/TurnosDetalleStyle.css"
import {decimalAdjust, EFECTIVO, ESTADO_CE, ESTADO_VALE, ESTADO_VENTA, TIPO_MOV_CAJA,} from "../../Global";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError} from "../Almacenes/AlmacenNotify";
import {calcularMovsCaja, getTotalRedondeo} from "../../helpers/utils";
import {handleSavePDF} from "../Caja/printDataCaja";
import moment from "moment";


class turnosDetalle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mostrarTurno: true,
            IdCaja: 0,
            dataCaj: {
                cajasSucursal: []
            },
            IdTurno: 0,
            dataTur: {
                turnos: []
            },
            //Info de movimientos por turnos
            IdMovimientos: 1,
            data: {
                movCajas: []
            },
            //Info de usuarios por sucursal
            IdUsuario: 0,
            dataUser: {
                usuariosSucursal: []
            },
            modalIsOpen: false,
            PDF: true,
            btnVerDetallesMov: true, //Se encarga de ver si se muestra o no el boton ver detalles movimiento en la lista. Acá SI es necesario ver detalles el movimiento
            fechaBusquedaInicio: moment().format('YYYY-MM-DD'), //Buscar los turnos desde una fecha
            fechaBusquedaFin: moment().format('YYYY-MM-DD'), //Buscar los turnos hasta una fecha
            //confirmar si hay data o hay un error
            isLoading: false,
            error: null,
            tiposPago: [],
            datosReporte: {},
            isLoadingReporte: false,
            montoInicial: 0,
            extImg: 'png',
            showPrintCaja: true,
            movCajas: [],
        }
        this.limpiarData = this.limpiarData.bind(this)
        this.obtenerCajas = this.obtenerCajas.bind(this);
        this.obtenerTurnos = this.obtenerTurnos.bind(this);
        this.obtenerUsuarios = this.obtenerUsuarios.bind(this);
        this.handleChangeFechaBusqueda = this.handleChangeFechaBusqueda.bind(this);
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this);
        this.ToDay = this.ToDay.bind(this)
        this.onClickGenerarReport = this.onClickGenerarReport.bind(this);
        this.onCambioTurnoChange = this.onCambioTurnoChange.bind(this);
        this.extractCajaInfo = this.extractCajaInfo.bind(this)
    }

    componentDidMount() {
        this.obtenerCajas();
        this.obtenerUsuarios();
        this.ToDay();
        this.fetchTiposPago();
    }

    fetchTiposPago() {
        fetch('/api/pagos/tipopagos')
            .then(async res => {
                let jsonRes = await res.json();
                jsonRes.respuesta.forEach(t => {
                    t['isTarjeta'] = t.Nombre.includes("Tarjeta")
                });
                this.setState({tiposPago: [...jsonRes.respuesta]});
            });
    }

    limpiarData = async () => {
        this.state.IdTurno = 0;
        this.setState({datosReporte: {}});
        await this.obtenerTurnos(0, false);
    }

    obtenerCajas = async () => {
        try {
            const response = await fetch(`/api/turnos/cajas/`);
            const data = await response.json();
            this.setState({
                isLoading: false,
                dataCaj: data,
                extImg: data.extImg
            })
        } catch (error) {
            this.setState({isLoading: false, error: error})
        }
    }

    obtenerUsuarios = async () => {
        try {
            const response = await fetch(`/api/turnos/usuarios`); //Trae turnos por Sucursal
            const data = await response.json();
            // console.log(data)
            this.setState({
                isLoading: false,
                dataUser: data
            })
        } catch (error) {
            this.setState({isLoading: false, error: error})
        }
    }

    obtenerTurnos = async (IdCaja, mostrarNotify) => {

        if (this.state.fechaBusquedaInicio == '' && mostrarNotify == true) {
            return Notify("Debes ingresar una fecha de inicio", "warning", 1500)
        }
        if (this.state.fechaBusquedaFin == '' && mostrarNotify == true) {
            return Notify("Debes ingresar una fecha de fin", "warning", 1500)
        }
        if (this.state.IdCaja == '' && mostrarNotify == true) {
            return Notify("Debes seleccionar una caja", "warning", 1500)
        }
        if (this.state.IdUsuario == '' && mostrarNotify == true) {
            return Notify("Debes seleccionar un usuario", "warning", 1500)
        }
        if (mostrarNotify) {
            try {
                const response = await fetch(`/api/turnos/turnosCaj/${IdCaja}/${this.state.fechaBusquedaInicio}/${this.state.fechaBusquedaFin}/${this.state.IdUsuario}`);
                const data = await response.json();
                this.setState({
                    dataTur: data
                })
                if (data.turnos != 0) {

                    this.setState({
                        mostrarTurno: false,
                    })
                } else {
                    this.setState({
                        mostrarTurno: true,
                    })
                    return Notify("No se han encontrado turnos, por favor revise los parámetros de búsqueda", "warning", 3500)
                }
            } catch (error) {
                this.setState({isLoading: false, error: error})
            }
        }
    }

    handleCloseModal = () => {
        this.setState({
            modalIsOpen: false
        })
    }

    handleChangeCaja = (e) => {
        this.setState({
            IdCaja: e.target.value
        })
        if (e.target.value == 0) {
            this.setState({
                IdTurno: 0
            })
        }
        this.setState({
            mostrarTurno: true
        })
        this.limpiarData()

    }

    handleChangeUsuario = async (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: value,
            PDF: true,
            IdTurno: 0,
            mostrarTurno: true
        })
        this.limpiarData()
    }

    handleChangeFechaBusqueda = async (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: value,
            PDF: true,
            IdTurno: 0,
        })
        this.limpiarData()
    }

    ToDay = () => {
        let today = new Date();
        let hoy = ''.concat(today.getFullYear())
        if ((today.getMonth() + 1) < 10) {
            hoy = hoy.concat('-0', (today.getMonth() + 1))
        } else {
            hoy = hoy.concat('-', (today.getMonth() + 1))
        }
        if ((today.getDate()) < 10) {
            hoy = hoy.concat('-0', (today.getDate()))
        } else {
            hoy = hoy.concat('-', (today.getDate()))
        }
        return hoy;
    }

    fechaFin = (fecha) => {
        if (fecha == null) {
            return "En curso"
        } else {
            return <Moment format="MMMM Do YYYY, h:mm:ss a">{fecha}</Moment>
        }
    }

    async onClickGenerarReport(e) {
        if (this.state.IdTurno.toString() === "0" || this.state.IdTurno.toString().length === 0)
            Notify("Debe seleccionar un turno", 'error')
        else {

            this.setState({isLoadingReporte: true, datosReporte: {}});

            const movCajas = await this.fetchMontosCaja()

            let objReporte = {
                tiposPago: [...this.state.tiposPago],
                totalBadTarjeta: 0,
                infoComprobantes: await this.getInfoComprobantes()
            }

            for (let i = 0; i < objReporte.tiposPago.length; i++) {
                objReporte.tiposPago[i].montoAceptado = await this.getTotalByComprobante(objReporte.tiposPago[i].IdTipoPago, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO},${ESTADO_CE.NINGUNO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
                objReporte.tiposPago[i].montoAnulado = await this.getTotalByComprobante(objReporte.tiposPago[i].IdTipoPago, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`)

                if (objReporte.tiposPago[i].isTarjeta)
                    objReporte.totalBadTarjeta += Number(objReporte.tiposPago[i].montoAnulado);
            }

            objReporte.sumTotal = 0;
            objReporte.tiposPago.forEach(t => objReporte.sumTotal += (t.montoAceptado - t.montoAnulado))


            const mtoTotalEfectivo = this.calcularMontoByTipoPago(objReporte.tiposPago, EFECTIVO);
            objReporte.totalIngresosCaja = await this.getTotalMovsCaja(this.state.IdTurno, TIPO_MOV_CAJA.ENTRADA)
            objReporte.totalSalidasCaja = await this.getTotalMovsCaja(this.state.IdTurno, TIPO_MOV_CAJA.SALIDA)

            objReporte.totalCaja = Number(this.state.montoInicial) + mtoTotalEfectivo + objReporte.totalIngresosCaja - (objReporte.totalSalidasCaja + objReporte.totalBadTarjeta)
            objReporte._totalMtoEfectivo = mtoTotalEfectivo

            this.setState({isLoadingReporte: false, datosReporte: objReporte, movCajas});
        }
    }

    async getInfoComprobantes() {
        const idTurno = this.state.IdTurno
        let res = await fetch(`/api/cajas/comprobantes-emitidos?idTurno=${idTurno}`)
        return await res.json()
    }

    async getTotalMovsCaja(idTurno, tipoMov) {
        let res = await fetch(`/api/turnos/total-movs-caja-sin-comprobante/${idTurno}/${tipoMov}`)
        let data = await res.json()
        return data.total.total;
    }

    calcularMontoByTipoPago(tiposPago, tipoPago) {
        let monto = 0;
        tiposPago.filter(t => t.IdTipoPago === tipoPago)
            .forEach(t => monto += (t.montoAceptado - t.montoAnulado));
        return monto;
    }

    async getTotalByComprobante(idsTipoPago, estadoVenta, estadoVale) {
        let queryParams =
            `idsTipoPago=${idsTipoPago}&
            idTurnoCaja=${this.state.IdTurno}&
            estadoVenta=${estadoVenta}&
            estadoVale=${estadoVale}            
            `.replace(/\s/g, "");
        let response = await fetch(`/api/turnos/total-ventas?${queryParams}`);
        let resJson = await response.json();
        return Number(resJson.total);
    }

    onCambioTurnoChange(e) {
        const {name, value} = e.target
        let turnos = this.state.dataTur.turnos.filter(t => t.IdTurnoCaja.toString() === value.toString())
        this.setState({
            [name]: value,
            PDF: true,
            montoInicial: turnos.length ? turnos[0].MontoInicial : 0,
            showPrintCaja: turnos.length ? turnos[0].FechaFin != null : false,
            datosReporte: {}
        })
    }

    extractCajaInfo() {
        pfetch({
            fetch: {
                url: `/api/cajas/info-cierre/v2?idTurno=${this.state.IdTurno}`,
                method: "GET",
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoading: true}),
            then: (d) => this.printCajaInfo(d),
            error: () => notificarError("Ha ocurrido un error al momento de extraer la información"),
            finally: () => null
        })
    }

    async printCajaInfo(d) {
        let [entradas, salidas, , salidasNormales] = calcularMovsCaja(this.state.movCajas)
        Object.keys(d).forEach(k => d[k] = Number(d[k]))
        const payload = {
            ...d,
            redondeoRVAceptados: await getTotalRedondeo(`${ESTADO_CE.ACEPTADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.PENDIENTE}`),
            redondeoRVAnulados: await getTotalRedondeo(`${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`),
            extImg: this.state.extImg,
            movCajas: this.state.movCajas,
            idTurno: this.state.IdTurno,
            totales: {
                entradas: Number(decimalAdjust('floor', entradas, -2)),
                salidas: Number(decimalAdjust('floor', salidas, -2)),
                salidasNormales: Number(salidasNormales),
                totalEfectOk: this.state.datosReporte._totalMtoEfectivo,
                totalEfectBad: 0,
            },
        }

        await handleSavePDF(payload);

        this.setState({isLoading: false})
    }

    async fetchMontosCaja() {
        const response = await fetch(`/api/cajas/`);
        const data = await response.json();
        return data.movCajas
    }

    render() {

        const {tiposPago} = this.state;

        return (
            <React.Fragment>

                <div className="container">

                    <div className="row mt-5">
                        <div className="col-sm ">
                            {/* <BotonCancelar2
                                onClick={() => this.props.history.goBack()}
                                className="ml-4 mb-2 mt-2"
                            /> */}
                        </div>
                    </div>

                    <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2">
                        <div className='col-12 col-md-6'>
                            <div className="col-sm-auto">
                                <b style={{ color: "red" }}>* </b> Inicio:
                            </div>
                            <div className="col-sm">
                                <input required
                                    name="fechaBusquedaInicio"
                                    className="input__lineaTurnos"
                                    onChange={this.handleChangeFechaBusqueda}
                                    defaultValue={this.state.fechaBusquedaInicio}
                                    type="date"
                                    max={this.ToDay()} />
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className="col-sm-auto">
                                <b style={{ color: "red" }}>* </b> Cierre:
                            </div>
                            <div className="col-sm">
                                <input required
                                    name="fechaBusquedaFin"
                                    className="input__lineaTurnos ml-2"
                                    onChange={this.handleChangeFechaBusqueda}
                                    defaultValue={this.state.fechaBusquedaFin}
                                    type="date"
                                    min={this.state.fechaBusquedaInicio === '' ? this.ToDay(new Date()) : this.state.fechaBusquedaInicio}
                                    max={this.ToDay()}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="caja-fondo-blanco-m-2 row mt-3 ml-auto mr-auto">
                        <div className="col-sm-auto">
                            <b style={{color: "red"}}>* </b> Cajas:
                        </div>
                        <div className="col-sm">
                            <select required className="input__lineaTurnos" name="IdCaja"
                                    onChange={this.handleChangeCaja} defaultValue={this.state.IdCaja}>
                                <option defaultValue value={0}>Seleccionar Caja</option>
                                {
                                    this.state.dataCaj.cajasSucursal.map((obj) => {
                                        return <option key={obj.IdCaja} value={obj.IdCaja}>{obj.Nombre}</option>
                                    })}
                            </select>
                        </div>
                        <div className="col-sm-auto mt-3">
                            <b style={{color: "red"}}>* </b> Usuario:
                        </div>
                        <div className="col-sm">
                            <select required className="input__lineaTurnos" name="IdUsuario"
                                    onChange={this.handleChangeUsuario} defaultValue={this.state.IdUsuario}
                                    aria-haspopup="true" aria-expanded="false">
                                <option defaultValue value={0}>Seleccionar Usuario</option>
                                {this.state.dataUser.usuariosSucursal.map((obj) => {
                                    return <option key={obj.IdUsuario} value={obj.IdUsuario}>{obj.Usuario}</option>
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="mt-3 d-flex justify-content-end">                       
                        <button className="btn btn-verde-mfp"
                                    onClick={() => {
                                        this.obtenerTurnos(this.state.IdCaja, true)
                                    }}
                        >
                            Cargar Turnos
                        </button>
                    </div>

                    <div hidden={this.state.mostrarTurno}>
                        <div className="row mt-3 caja-fondo-blanco-m-2 ml-auto mr-auto">
                            <div className="col-sm-auto">
                                <b style={{color: "red"}}>* </b> Turnos:
                            </div>
                            <div className="col-sm">
                                <select required className="input__lineaTurnos" name="IdTurno"
                                        value={this.state.IdTurno} onChange={this.onCambioTurnoChange}
                                        aria-haspopup="true" aria-expanded="false">
                                    <option defaultValue value={0}>Seleccionar Turno</option>
                                    {this.state.dataTur.turnos.map((obj) => {
                                        if (obj.FechaFin !== null) {
                                            return <option key={obj.IdTurnoCaja}
                                                           value={obj.IdTurnoCaja}>Apertura: {obj.FechaInicio} -
                                                Cierre: {obj.FechaFin}</option>
                                        } else {
                                            return <option key={obj.IdTurnoCaja}
                                                           value={obj.IdTurnoCaja}>Apertura: {obj.FechaInicio} - Cierre:
                                                Turno en curso</option>
                                        }
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="mt-3 d-flex justify-content-end">
                            <BotonLetras onClick={this.onClickGenerarReport}
                                            disabled={this.state.isLoadingReporte}>
                                {this.state.isLoadingReporte ? "Cargando" : "Generar reporte"}
                            </BotonLetras>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm">
                                <div className="alert alert-info mt-3">
                                    Las anulaciones de ventas que contuvo los siguientes modos de pago
                                    <ul>
                                        <li>Master</li>
                                        <li>Visa</li>
                                        <li>American</li>
                                    </ul>
                                    En caja se descuentan del <strong>efectivo</strong>.
                                    Si hay una diferencia debe sumar las anulaciones y restárselas al efectivo en esta
                                    vista.
                                </div>
                            </div>
                        </div>

                        {
                            Object.keys(this.state.datosReporte).length ? (
                                <div>
                                    <div className="row mt-3 caja-fondo-blanco-m-2 ml-auto mr-auto">
                                        <div className="col-sm">
                                            <label>Comprobantes emitidos</label> <br/>
                                            <ul>
                                                {this.state.datosReporte.infoComprobantes.map((d, i) => <li key={i}>
                                                    {d.Descripcion}: {d.Serie} - {d.Primero} - {d.Ultimo}
                                                </li>)}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-auto" style={{color: "#01a59c"}}>
                                            Total a
                                            entregar
                                            efectivo: {decimalAdjust('floor', this.state.datosReporte.totalCaja, -2)}
                                        </div>
                                        <div className="col-sm-auto">
                                            Total ingresos
                                            caja: {decimalAdjust('floor', this.state.datosReporte.totalIngresosCaja, -2)}
                                        </div>
                                        <div className="col-sm-auto">
                                            Total salidas
                                            caja: {decimalAdjust('floor', this.state.datosReporte.totalSalidasCaja, -2)}
                                        </div>
                                        <div className="col-sm-auto">
                                            Monto Apertura: {decimalAdjust('floor', this.state.montoInicial, -2)}
                                        </div>
                                        <div className="col-sm-auto">
                                            {this.state.showPrintCaja && <button disabled={this.state.isLoading}
                                                                                 onClick={this.extractCajaInfo}
                                                                                 className="btn btn-verde-mfp">
                                                {this.state.isLoading ? 'Cargando' : 'Imprimir ticket de caja'}
                                            </button>}
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Tipo de pago</th>
                                                <th scope="col">Monto aceptados S./</th>
                                                <th scope="col">Monto anulados S./</th>
                                                <th scope="col">Total S./</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                tiposPago.map((t, i) => (
                                                    <tr key={`tr_${i}`}>
                                                        <td>{t.Nombre}</td>
                                                        <td>{t.montoAceptado}</td>
                                                        <td>{t.montoAnulado}</td>
                                                        <td>{t.montoAceptado - t.montoAnulado}</td>
                                                    </tr>
                                                ))
                                            }
                                            <tr>
                                                <td>
                                                    Totales
                                                </td>
                                                <td>
                                                    {decimalAdjust('floor', tiposPago.map(t => t.montoAceptado).reduce((a, b) => a + b, 0), -6)}
                                                </td>
                                                <td>
                                                    {decimalAdjust('floor', tiposPago.map(t => t.montoAnulado).reduce((a, b) => a + b, 0), -6)}
                                                </td>
                                                <td>
                                                    {decimalAdjust('floor', this.state.datosReporte.sumTotal, -6)}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm">
                                            <h4>Información de entradas y salidas</h4>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th>Tipo</th>
                                                    <th>Fecha</th>
                                                    <th>Observación</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.movCajas.map((m, i) => (
                                                    <tr key={i}>
                                                        <td>{m.Tipo}</td>
                                                        <td>{moment(m.FechaHora).format("DD/MM/YYYY HH:mm")}</td>
                                                        <td>{m.Observacion && m.Observacion.length ? m.Observacion : "No se ingresó observación"}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                            ) : null
                        }
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default turnosDetalle;
