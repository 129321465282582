import jsPDF from "jspdf";
import {loadLogoByRUC} from "../../helpers/funcionesGlobales";
import {Section, Style} from "../../pages/RegistroVentas/ClasesImpresion";
import {decimalAdjust, TIPO_MOV_CAJA} from "../../Global";
import moment from "moment";
import printBlob from "../../helpers/printBlob";


async function getTotalByComprobante(idTpoPago, idTurno) {
    const ESTADO_VENTA_TODOS = "Aceptado,Pendiente,PROCESADA,Rechazado";
    const ESTADO_VALE_TODOS = "PENDIENTE,ANULADO,PROCESADO";
    const ESTADO_VENTA_ANULADOS = "Anulado,PROCESADA";
    const ESTADO_VALE_ANULADOS = "ANULADO";


    const fetchTotal = async (idTipoPago, idTurnoCaja, estadoVenta, estadoVale) => {
        let queryParams =
            `idsTipoPago=${idTipoPago}&
            idTurnoCaja=${idTurnoCaja}&
            estadoVenta=${estadoVenta}&
            estadoVale=${estadoVale}            
            `.replace(/\s/g, "");
        let response = await fetch(`/api/turnos/total-ventas?${queryParams}`);
        let resJson = await response.json();
        return Number(resJson.total);
    }

    const totalTodos = await fetchTotal(idTpoPago, idTurno, ESTADO_VENTA_TODOS, ESTADO_VALE_TODOS);
    const totalAnulado = await fetchTotal(idTpoPago, idTurno, ESTADO_VENTA_ANULADOS, ESTADO_VALE_ANULADOS);

    return {
        todo: totalTodos,
        anulado: totalAnulado,
        total: (decimalAdjust('floor', totalTodos - totalAnulado, -2))
    }
}

export const ticketProductoPDF = async (product) => {
    const response = await fetch(`/api/puntosVenta/pdf/custom`);
    const pdf = await response.json();
    
    const {
        HojaAltura,
        HojaAncho,
        HojaOrientacion,
        SeccionPrincipalAltura,
        SeccionPrincipalAncho,
        SeccionPrincipalAlineacion,
        SeccionPrincipalEjeY,
        SeccionPrincipalEjeX,
        TextoPrecioTamano,
        TextoPrincipalTamano
    } = pdf
    const mmPageSize = [HojaAltura, HojaAncho];
    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
    const orientacion = HojaOrientacion == 'vertical' ? 'portrait' : 'landscape'
    var doc = new jsPDF({
        orientation: orientacion,
        unit: "mm",
        format: ptPageSize
    });
    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const ProductoStyle = new Style(TextoPrincipalTamano, "bold", 0.5, SeccionPrincipalAlineacion);
    const ProductoSection = new Section(
        doc,
        SeccionPrincipalEjeX,
        SeccionPrincipalEjeY,
        ProductoStyle,
        SeccionPrincipalAncho,
        null,
        1
    );
    ProductoSection.write(["  ", `${product.nombre}`.toUpperCase()]); //mostrar dos string en el mismo write
    ProductoSection.drawBorder(true, true, true, false);
    const PrecioTittleStyle = new Style(TextoPrecioTamano, "normal", 0.5, 'center');
    const PrecioTittleSection = new Section(
        doc,
        SeccionPrincipalEjeX,
        ProductoSection.endY,
        PrecioTittleStyle,
        ProductoSection.width + 2,
        null,
        1,
        2
    );

    PrecioTittleSection.write(`S/ ${isNaN(product.PrecioConIgv) ? 0 : decimalAdjust("round", product.PrecioConIgv, -2)}`);
    PrecioTittleSection.drawBorder(true, false, true, true);
    window.open(doc.output("bloburl"), "_blank");
}

export const registroMovCajaPDF = async (data, isCopy = false) => {
    let infoPdf = await TraerInfoPDF(data.idTurno);
    const mmPageSize = [80, 3276];

    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: ptPageSize
    });

    let nombreArchivo = `${moment(new Date()).format('YYYY_MM_DD-HH_mm')}` + "-" + `${infoPdf[0][0][0].NombreCaja}`;

    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    //console.log({data})
    //const logoP = loadLogoByRUC(infoPdf[0][0][0].Ruc, 'logo', data.extImg);
    //const logo = await logoP;
    //doc.addImage(logo, "JPEG", 9, 5, 60, 18);

    const ComprobanteStyle = new Style(10, "bold", 1.3, 'center');
    const ComprobanteSection = new Section(
        doc,
        3,
        2,
        ComprobanteStyle,
        74,
        null,
        1
    );

    const headers = [isCopy ? "MOVIMIENTO DE CAJA (COPIA)" : "MOVIMIENTO DE CAJA", `${infoPdf[0][0][0].NombreCaja}`.toUpperCase()]

    ComprobanteSection.write(headers); //mostrar dos string en el mismo write
    ComprobanteSection.drawBorder(false, true);

    /** Empresa Info**/

    const EmpresaTittleStyle = new Style(9, "normal", 1);
    const EmpresaTittleSection = new Section(
        doc,
        4,
        ComprobanteSection.endY + 3.2,
        EmpresaTittleStyle,
        ComprobanteSection.width
    );

    EmpresaTittleSection.write(`${infoPdf[0][0][0].Ruc}`);
    EmpresaTittleSection.write(`${infoPdf[0][0][0].RazonSocial}`.toUpperCase());

    const EmpresaDataStyle = new Style(7, "normal", 1);
    const EmpresaDataSection = new Section(
        doc,
        EmpresaTittleSection.x,
        EmpresaTittleSection.endY + 3.2,
        EmpresaDataStyle,
        EmpresaTittleSection.width
    );
    EmpresaDataSection.write(`${infoPdf[0][0][0].Direccion}`.toUpperCase());
    EmpresaDataSection.write(`${infoPdf[0][0][0].Nombre}`.toUpperCase());

    const usuarioStyle = new Style(8, "normal", 1);
    const usuarioSection = new Section(
        doc,
        EmpresaDataSection.x,
        EmpresaDataSection.endY + 4,
        usuarioStyle,
        EmpresaDataSection.width
    );

    usuarioSection.write(`FECHA DE EMISIÓN: ${moment(new Date()).format('DD/MM/YYYY HH:mm')}`);
    usuarioSection.write(`USUARIO: ${infoPdf[0][0][0].Usuario}`);
    usuarioSection.write(`EMPLEADO: ${infoPdf[0][0][0].nomEmpleado}`);
    usuarioSection.write(` `);


    const TipoMovStyle = new Style(10, "normal", 1, 'left');
    const TipoMovSection = new Section(
        doc,
        3,
        usuarioSection.endY + 2,
        TipoMovStyle,
        pageWidth - 6,
        null,
        1
    );
    TipoMovSection.write(`TIPO DE MOVIMIENTO: ${data.data.TipoMovimiento.toUpperCase()}        ` + `MONTO: S./ ${decimalAdjust('round', data.data.Monto, -2)}`,);
    TipoMovSection.drawBorder(false, true)


    const ObservacionStyle = new Style(10, "normal", 1, 'left');
    const ObservacionSection = new Section(
        doc,
        3,
        TipoMovSection.endY + 2,
        ObservacionStyle,
        pageWidth - 6,
        null,
        1
    );
    ObservacionSection.write(`OBSERVACION: ${data.data.Observacion ? data.data.Observacion.toUpperCase() : 'No hay observaciones.'}`);

    const LineaStyle = new Style(10, "normal", 1, 'left');
    const LineaSection = new Section(
        doc,
        3,
        ObservacionSection.endY + 4,
        LineaStyle,
        pageWidth - 6,
        null,
        1
    );
    LineaSection.write(`________________`,);
    const FirmaStyle = new Style(10, "normal", 1, 'left');
    const FirmaSection = new Section(
        doc,
        3,
        LineaSection.endY + 1,
        FirmaStyle,
        pageWidth - 6,
        null,
        1
    );
    FirmaSection.write(`           firma`,);

    doc.autoPrint();
    const bloburl = doc.output("bloburl");
    printBlob(bloburl)
}


export const handleSavePDF = async (data) => {
    let infoPdf = await TraerInfoPDF(data.idTurno);
    const mmPageSize = [80, 3276];

    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: ptPageSize
    });

    let nombreArchivo = `${moment(new Date()).format('YYYY_MM_DD-HH_mm')}` + "-" + `${infoPdf[0][0][0].NombreCaja}`;

    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    const logoP = loadLogoByRUC(infoPdf[0][0][0].Ruc, 'logo', data.extImg);
    const logo = await logoP;
    doc.addImage(logo, "JPEG", 9, 5, 60, 18);


    const ComprobanteStyle = new Style(10, "bold", 1.3, 'center');
    const ComprobanteSection = new Section(
        doc,
        3,
        26,
        ComprobanteStyle,
        74,
        null,
        1
    );

    ComprobanteSection.write(["CIERRE DE CAJA".toUpperCase(), `${infoPdf[0][0][0].NombreCaja}`.toUpperCase()]); //mostrar dos string en el mismo write
    ComprobanteSection.drawBorder(false, true);

    /** Empresa Info**/

    const EmpresaTittleStyle = new Style(9, "normal", 1);
    const EmpresaTittleSection = new Section(
        doc,
        4,
        ComprobanteSection.endY + 3.2,
        EmpresaTittleStyle,
        ComprobanteSection.width
    );

    EmpresaTittleSection.write(`${infoPdf[0][0][0].Ruc}`);
    EmpresaTittleSection.write(`${infoPdf[0][0][0].RazonSocial}`.toUpperCase());

    const EmpresaDataStyle = new Style(7, "normal", 1);
    const EmpresaDataSection = new Section(
        doc,
        EmpresaTittleSection.x,
        EmpresaTittleSection.endY + 3.2,
        EmpresaDataStyle,
        EmpresaTittleSection.width
    );
    EmpresaDataSection.write(`${infoPdf[0][0][0].Direccion}`.toUpperCase());
    EmpresaDataSection.write(`${infoPdf[0][0][0].Nombre}`.toUpperCase());

    const usuarioStyle = new Style(8, "normal", 1);
    const usuarioSection = new Section(
        doc,
        EmpresaDataSection.x,
        EmpresaDataSection.endY + 4,
        usuarioStyle,
        EmpresaDataSection.width
    );

    usuarioSection.write(`FECHA DE EMISIÓN: ${moment(new Date()).format('DD/MM/YYYY HH:mm')}`);
    usuarioSection.write(`USUARIO: ${infoPdf[0][0][0].Usuario}`);
    usuarioSection.write(`EMPLEADO: ${infoPdf[0][0][0].nomEmpleado}`);
    usuarioSection.write(` `);


    const totalEntregarStyle = new Style(8, "bold", 1);
    const totalEntregarSection = new Section(
        doc,
        usuarioSection.x,
        usuarioSection.endY + 2,
        totalEntregarStyle,
        usuarioSection.width
    );
    totalEntregarSection.write(`TOTAL A ENTREGAR: S./ ${decimalAdjust('round', data.MontoFinalEfectivoSistema + data.MontoInicial, -2)}`);

    const MontosStyle = new Style(10, "bold", 1.3, 'center');
    const MontosSection = new Section(
        doc,
        3,
        totalEntregarSection.endY + 2,
        MontosStyle,
        pageWidth - 6,
        null,
        1
    );

    MontosSection.write(`      MÉTODO DE PAGO                    MONTO`);
    MontosSection.drawBorder(false, true);

    const TipoMontosStyle = new Style(9, "bold", 1.3, 'center');
    const TipoMontosSection = new Section(
        doc,
        3,
        MontosSection.endY,
        TipoMontosStyle,
        pageWidth - 6,
        null,
        1
    );

    TipoMontosSection.write(`                                         FISICO            SISTEMA`);
    TipoMontosSection.drawBorder(false, false, false, true);

    const TipoMontoNumberStyle = new Style(9, "normal", 1, 'right');
    const TipoMontoNumberSection = new Section(
        doc,
        4,
        TipoMontosSection.endY + 4,
        TipoMontoNumberStyle,
        MontosSection.width
    );

    const TipoMontoTextoStyle = new Style(9, "normal", 1, 'left');
    const TipoMontoTextoSection = new Section(
        doc,
        4,
        TipoMontosSection.endY + 4,
        TipoMontoTextoStyle,
        MontosSection.width
    );

    const montoFinalSistemaTunki = (await getTotalByComprobante(8, data.idTurno)).total;
    const montoFinalFisicoTunki = +data.MontoFinalTunki;

    const montoFinalSistemaPlin = (await getTotalByComprobante(9, data.idTurno)).total;
    const montoFinalFisicoPlin = +data.MontoFinalPlin;

    const montoFinalSistemaYape = (await getTotalByComprobante(10, data.idTurno)).total;
    const montoFinalFisicoYape = +data.MontoFinalYape;

    const montoFinalSistemaRappi = (await getTotalByComprobante(11, data.idTurno)).total;
    const montoFinalFisicoRappi = +data.MontoFinalRappi;

    let TOTAL = data.MontoFinalEfectivoSistema + data.MontoFinalTarjetaMasterCardSistema +
        data.MontoFinalTarjetaVisaSistema + data.MontoFinalTarjetaAmericanSistema +
        data.MontoFinalOtraTarjetaSistema + data.MontoFinalCreditoSistema + montoFinalSistemaYape + montoFinalSistemaPlin + montoFinalSistemaTunki + montoFinalSistemaRappi;

    let simbolo = infoPdf[0][0][0].Simbolo;

    const writeMontos = (simbolo, fisico, sistema, spaces = 20) => {
        const _mtoFisico = `${simbolo + '. ' + (decimalAdjust('floor', +fisico, -2)).toFixed(2)}`;
        const _mtoSistema = `${simbolo + '. ' + (decimalAdjust('floor', +sistema, -2)).toFixed(2)}`;
        
        const _mtos = `${_mtoFisico.padStart(spaces, " ") + _mtoSistema.padStart(spaces, " ")}`;

        TipoMontoNumberSection.write(_mtos)
    }

    TipoMontoTextoSection.write(`${data.MontoFinalEfectivoSistema !== 0 ? "Ventas Efectivo".padEnd(27, "") : ''}`); //Ventas Efectivo -> Contienen TODAS las ventas realizadas (Incluyendo las anuladas) para levar el contro TOTAL de ventas realizadas (indiferente de si se anularon o no)           ${infoPdf[0][0][0].Simbolo}

    if (data.MontoFinalEfectivoSistema !== 0) {
        writeMontos(simbolo, data.MontoFinalEfectivoFisico, data.MontoFinalEfectivoSistema)
    }
    
    TipoMontoTextoSection.write(`${data.MontoFinalTarjetaMasterCardSistema !== 0 ? "T. MasterCard".padEnd(27, "") : ''}`);

    if (data.MontoFinalTarjetaMasterCardSistema !== 0) {
        writeMontos(simbolo, data.MontoFinalTarjetaMasterCardFisico, data.MontoFinalTarjetaMasterCardSistema)
    }

    TipoMontoTextoSection.write(`${data.MontoFinalTarjetaVisaSistema !== 0 ? "T. Visa".padEnd(27, "") : ''}`);
    
    if (data.MontoFinalTarjetaVisaSistema !== 0) {
        writeMontos(simbolo, data.MontoFinalTarjetaVisaFisico, data.MontoFinalTarjetaVisaSistema)
    }

    TipoMontoTextoSection.write(`${data.MontoFinalTarjetaAmericanSistema !== 0 ? "T. American".padEnd(27, "") : ''}`);
    
    if (data.MontoFinalTarjetaAmericanSistema !== 0) {
        writeMontos(simbolo, data.MontoFinalTarjetaAmericanFisico, data.MontoFinalTarjetaAmericanSistema)
    }

    TipoMontoTextoSection.write(`${data.MontoFinalFpay !== 0 ? "Fpay".padEnd(27, "") : ''}`);
    
    if (data.MontoFinalFpay !== 0) {
        writeMontos(simbolo, data.MontoFinalFpay, data.MontoFinalFpay);
    }

    TipoMontoTextoSection.write(`${montoFinalSistemaYape !== 0 ? "Yape".padEnd(27, "")  : ''}`);

    if (montoFinalSistemaYape !== 0) {
        writeMontos(simbolo, montoFinalFisicoYape, montoFinalSistemaYape);
    }

    TipoMontoTextoSection.write(`${montoFinalSistemaPlin !== 0 ? "Plin".padEnd(27, "")  : ''}`);
    
    if (montoFinalSistemaPlin !== 0) {
        writeMontos(simbolo, montoFinalFisicoPlin, montoFinalSistemaPlin);
    }

    TipoMontoTextoSection.write(`${montoFinalSistemaTunki !== 0 ? "Tunki".padEnd(27, "")  : ''}`);
    
    if (montoFinalSistemaTunki !== 0) {
        writeMontos(simbolo, montoFinalFisicoTunki, montoFinalSistemaTunki);
    }

    TipoMontoTextoSection.write(`${montoFinalSistemaRappi !== 0 ? "Rappi".padEnd(27, "")  : ''}`);
    
    if (montoFinalSistemaRappi !== 0) {
        writeMontos(simbolo, montoFinalFisicoRappi, montoFinalSistemaRappi);
    }

    TipoMontoTextoSection.write(`${data.MontoFinalOtraTarjetaSistema !== 0 ? "Otra Tarjeta".padEnd(27, "")  : ''}`);

    if (data.MontoFinalOtraTarjetaSistema !== 0) {
        writeMontos(simbolo, data.MontoFinalOtraTarjetaFisico, data.MontoFinalOtraTarjetaSistema);
    }

    TipoMontoTextoSection.write(` `);
    TipoMontoNumberSection.write(` `);

    //tipo venta section

    const tipoVentaStyle = new Style(10, "bold", 1.3, 'center');
    const tipoVentaSection = new Section(
        doc,
        3,
        TipoMontoNumberSection.endY + 2,
        tipoVentaStyle,
        pageWidth - 6,
        null,
        1
    );

    tipoVentaSection.write(`      TIPO VENTA                          MONTO`);
    tipoVentaSection.drawBorder(false, true);

    const tipoVentaNumberStyle = new Style(9, "normal", 1, 'right');
    const tipoVentaNumberSection = new Section(
        doc,
        4,
        tipoVentaSection.endY + 4,
        tipoVentaNumberStyle,
        tipoVentaSection.width
    );

    const tipoVentaTextoStyle = new Style(9, "normal", 1, 'left');
    const tipoVentaTextoSection = new Section(
        doc,
        4,
        tipoVentaSection.endY + 4,
        tipoVentaTextoStyle,
        tipoVentaSection.width
    );

    let comprobantesAnulados = data.movCajas.filter(m => m.Tipo === TIPO_MOV_CAJA.SALIDA && m.Observacion.includes("Anulación del comprobante"))
    let sumComprobantesAnulados = 0;

    comprobantesAnulados.forEach((m) => sumComprobantesAnulados += m.Monto)

    const totalSalidas = Number(data.totales.salidas) - sumComprobantesAnulados;
    const totalEntradas = data.totales.entradas;

    let ventasContado = data.totales.totalEfectOk - data.totales.totalEfectBad + data.MontoFinalTarjetaMasterCardSistema +
        data.MontoFinalTarjetaVisaSistema + data.MontoFinalTarjetaAmericanSistema +
        data.MontoFinalOtraTarjetaSistema + montoFinalSistemaYape + montoFinalSistemaPlin + montoFinalSistemaTunki + montoFinalSistemaRappi - totalSalidas;

    const _total = data.MontoFinalCreditoSistema + ventasContado;

    // tipoVentaTextoSection.write(`${ventasContado !== 0 ? "Ventas Contado           " + infoPdf[0][0][0].Simbolo : ''}`);
    // tipoVentaNumberSection.write(`${ventasContado !== 0 ? (decimalAdjust('floor', ventasContado, -2)) : ''}`);
    tipoVentaTextoSection.write(`${ventasContado !== 0 ? "Ventas Contado           " : ''}`);
    tipoVentaNumberSection.write(`${ventasContado !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (decimalAdjust('floor', ventasContado, -2)) : ''}`);


    tipoVentaTextoSection.write(`${data.MontoFinalCreditoSistema !== 0 ? "Ventas Crédito             " : ''}`);
    tipoVentaNumberSection.write(`${data.MontoFinalCreditoSistema !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (decimalAdjust('floor', data.MontoFinalCreditoSistema, -2)) : ''}`);

    tipoVentaTextoSection.write(` `);
    tipoVentaNumberSection.write(` `);


    tipoVentaTextoSection.write(`${_total !== 0 ? "TOTAL VENTAS          " : ''}`);
    tipoVentaNumberSection.write(`${_total !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (decimalAdjust('floor', _total, -2)) : ''}`);


    const TotalStyle = new Style(8, "normal", 1);
    const TotalSection = new Section(
        doc,
        4,
        tipoVentaNumberSection.endY + 1,
        TotalStyle,
        tipoVentaNumberSection.width
    );

    const letrasTotal = await numeroALetras(decimalAdjust('floor', TOTAL, -2))

    TotalSection.write(`${TOTAL !== 0 ? "SON " + letrasTotal : ''}`);


    //tipo venta end section


    //efectivo section
    const EfectivoEnCajaStyle = new Style(10, "bold", 1, 'center');
    const EfectivoEnCajaSection = new Section(
        doc,
        3,
        TotalSection.endY + 4,
        EfectivoEnCajaStyle,
        pageWidth - 6,
        null,
        1
    );

    EfectivoEnCajaSection.write("MOVIMIENTO CAJA");
    EfectivoEnCajaSection.drawBorder(false, true);

    const DatosTextoStyle = new Style(9, "normal", 1, "left");
    const DatosTextoSection = new Section(
        doc,
        4,
        EfectivoEnCajaSection.endY + 4,
        DatosTextoStyle,
        EfectivoEnCajaSection.width
    );

    const DatosNumberStyle = new Style(9, "normal", 1, "right");
    const DatosNumberSection = new Section(
        doc,
        4,
        EfectivoEnCajaSection.endY + 4,
        DatosNumberStyle,
        EfectivoEnCajaSection.width
    );

    DatosTextoSection.write(`${data.MontoInicial !== 0 ? "Monto de apertura            " : ''}`);
    DatosNumberSection.write(`${data.MontoInicial !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (data.MontoInicial) : ''}`);

    DatosTextoSection.write(`${totalEntradas !== 0 ? "Total entradas                       " : ''}`);
    DatosNumberSection.write(`${totalEntradas !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (totalEntradas) : ''}`);

    DatosTextoSection.write(`${totalSalidas !== 0 ? "Total salidas                          " : ''}`);
    DatosNumberSection.write(`${totalSalidas !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (totalSalidas) : ''}`);

    DatosTextoSection.write(` `);
    DatosNumberSection.write(` `);

    let MontoTotalFisico = Number(data.MontoFinalEfectivoFisico) + Number(data.MontoFinalTarjetaMasterCardFisico) +
        Number(data.MontoFinalTarjetaVisaFisico) + Number(data.MontoFinalTarjetaAmericanFisico) + Number(data.MontoFinalCreditoSistema) +
        Number(data.MontoFinalOtraTarjetaFisico) + Number(data.MontoFinalFpay) + Number(montoFinalFisicoYape) + Number(montoFinalFisicoPlin) + Number(montoFinalFisicoTunki) + Number(montoFinalFisicoRappi);

    let MontoTotalSistema = data.MontoFinalEfectivoSistema + data.MontoFinalTarjetaMasterCardSistema +
        data.MontoFinalTarjetaVisaSistema + data.MontoFinalTarjetaAmericanSistema +
        data.MontoFinalOtraTarjetaSistema + data.MontoFinalCreditoSistema + montoFinalSistemaYape + montoFinalFisicoPlin + montoFinalFisicoTunki + montoFinalFisicoRappi;

    const DiferenciaTitleStyle = new Style(10, "bold", 1, 'center');
    const DiferenciaTitleSection = new Section(
        doc,
        3,
        DatosNumberSection.endY + 4,
        DiferenciaTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    DiferenciaTitleSection.write("     DIFERENCIA ");
    DiferenciaTitleSection.write("SISTEMA - FISICO");
    DiferenciaTitleSection.drawBorder(false, true);


    const DiferenciaTextoStyle = new Style(9, "normal", 1, "left");
    const DiferenciaTextoSection = new Section(
        doc,
        4,
        DiferenciaTitleSection.endY + 4,
        DiferenciaTextoStyle,
        DiferenciaTitleSection.width
    );
    const DiferenciaNumberStyle = new Style(9, "normal", 1, "right");
    const DiferenciaNumberSection = new Section(
        doc,
        4,
        DiferenciaTitleSection.endY + 4,
        DiferenciaNumberStyle,
        DiferenciaTitleSection.width
    );
    const diferenciaSistema = _total - MontoTotalFisico

    DiferenciaTextoSection.write(`${MontoTotalSistema !== 0 ? "M. TOTAL SISTEMA " : ''}`);
    DiferenciaNumberSection.write(`${MontoTotalSistema !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + (_total).toFixed(2).padStart(1, " ") : ''}`);

    // DiferenciaTextoSection.write(`${MontoTotalFisico !== 0 ? "M. TOTAL FISICO     " + infoPdf[0][0][0].Simbolo : ''}`);
    // DiferenciaNumberSection.write(`${MontoTotalFisico !== 0 ? (MontoTotalFisico) : ''}`);

    DiferenciaTextoSection.write(`${MontoTotalFisico !== 0 ? "M. TOTAL FISICO " : ''}`);
    DiferenciaNumberSection.write(`${MontoTotalFisico !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + (MontoTotalFisico).toFixed(2).padStart(1, " ") : ''}`);


    DiferenciaTextoSection.write(`${diferenciaSistema !== 0 ? "Diferencia " : ''}`);
    DiferenciaNumberSection.write(`${diferenciaSistema !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaSistema, -2).toFixed(2) : ''}`);

    DiferenciaTextoSection.write(`${(data.MontoFinalTarjetaMasterCardSistema - data.MontoFinalTarjetaMasterCardFisico) !== 0 ? "T. Mastercard " : ''}`);
    DiferenciaNumberSection.write(`${(data.MontoFinalTarjetaMasterCardSistema - data.MontoFinalTarjetaMasterCardFisico) !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (data.MontoFinalTarjetaMasterCardSistema - data.MontoFinalTarjetaMasterCardFisico).toFixed(2).padStart(1, " ") : ''}`);

    DiferenciaTextoSection.write(`${(data.MontoFinalTarjetaVisaSistema - data.MontoFinalTarjetaVisaFisico) !== 0 ? "T. Visa " : ''}`);
    DiferenciaNumberSection.write(`${(data.MontoFinalTarjetaVisaSistema - data.MontoFinalTarjetaVisaFisico) !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (data.MontoFinalTarjetaVisaSistema - data.MontoFinalTarjetaVisaFisico).toFixed(2).padStart(1, " ") : ''}`);

    DiferenciaTextoSection.write(`${(data.MontoFinalTarjetaAmericanSistema - data.MontoFinalTarjetaAmericanFisico) !== 0 ? "T. American                " : ''}`);
    DiferenciaNumberSection.write(`${(data.MontoFinalTarjetaAmericanSistema - data.MontoFinalTarjetaAmericanFisico) !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (data.MontoFinalTarjetaAmericanSistema - data.MontoFinalTarjetaAmericanFisico).toFixed(2).padStart(1, " ") : ''}`);

    DiferenciaTextoSection.write(`${(data.MontoFinalOtraTarjetaSistema - data.MontoFinalOtraTarjetaFisico) !== 0 ? "Otra Tarjeta                " : ''}`);
    DiferenciaNumberSection.write(`${(data.MontoFinalOtraTarjetaSistema - data.MontoFinalOtraTarjetaFisico) !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (data.MontoFinalOtraTarjetaSistema - data.MontoFinalOtraTarjetaFisico).toFixed(2).padStart(1, " ") : ''}`);

    const RedondeoTitleStyle = new Style(10, "bold", 1, 'center');
    const RedondeoTitleSection = new Section(
        doc,
        3,
        DiferenciaTextoSection.endY + 4,
        RedondeoTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    RedondeoTitleSection.write("REDONDEOS DEL TURNO");
    RedondeoTitleSection.drawBorder(false, true);

    const RedondeoTextoStyle = new Style(9, "normal", 1, "left");
    const RedondeoTextoSection = new Section(
        doc,
        4,
        RedondeoTitleSection.endY + 4,
        RedondeoTextoStyle,
        RedondeoTitleSection.width
    );

    RedondeoTextoSection.write(`Redondeo Comprobante Aceptados: ${data.redondeoRVAceptados}`);
    RedondeoTextoSection.write(`Redondeo Comprobante Anulados:  ${data.redondeoRVAnulados}`);

    const CorrelativosTitleStyle = new Style(10, "bold", 1, 'center');
    const CorrelativosTitleSection = new Section(
        doc,
        3,
        RedondeoTextoSection.endY + 4,
        CorrelativosTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    CorrelativosTitleSection.write("COMPROBANTES EMITIDOS");
    CorrelativosTitleSection.drawBorder(false, true);

    const CorrelativosStyle = new Style(9, "normal", 1);
    const CorrelativosSection = new Section(
        doc,
        4,
        CorrelativosTitleSection.endY + 4,
        CorrelativosStyle,
        CorrelativosTitleSection.width
    );

    if (infoPdf[0][1].length !== 0) {
        infoPdf[0][1].map((obj) => {
            CorrelativosSection.write(`${obj.Descripcion}`);
            CorrelativosSection.write(`                     ${obj.Serie} ${obj.Primero} - ${obj.Ultimo} (${+obj.Ultimo - +obj.Primero + 1})`);
        })
    }
    if (infoPdf[0][2].length !== 0) {
        infoPdf[0][2].map((obj) => {
            CorrelativosSection.write(`${obj.Descripcion}`);
            CorrelativosSection.write(`                     ${obj.Serie} ${obj.Primero} - ${obj.Ultimo} (${+obj.Ultimo - +obj.Primero + 1})`);
        })
    }


    const comprobantesAnuladosTitleStyle = new Style(10, "bold", 1, 'center');
    const comprobantesAnuladosTitleSection = new Section(
        doc,
        3,
        CorrelativosSection.endY + 4,
        comprobantesAnuladosTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    comprobantesAnuladosTitleSection.write("INFO. COMPROBANTES ANULADOS");
    comprobantesAnuladosTitleSection.drawBorder(false, true);

    const compAnuladosStyle = new Style(9, "normal", 1);
    const compAnuladosSection = new Section(
        doc,
        4,
        comprobantesAnuladosTitleSection.endY + 4,
        compAnuladosStyle,
        comprobantesAnuladosTitleSection.width
    );

    const movsCajasAnulados = data.movCajas.filter(m => m.Tipo === TIPO_MOV_CAJA.SALIDA && m.Observacion.includes("Anulación del comprobante"));
    movsCajasAnulados.forEach(m => compAnuladosSection.write(`${m.Observacion}: S/. ${m.Monto} `))

    let sum = 0;
    movsCajasAnulados.forEach(m => sum += m.Monto);
    compAnuladosSection.write(`Monto Total de comprobantes anulados: S/. ${sum} `)


    // mov venta section
    const movVentaStyle = new Style(10, "bold", 1.3, 'center');
    const movVentaSection = new Section(
        doc,
        3,
        compAnuladosSection.endY + 3,
        movVentaStyle,
        pageWidth - 6,
        null,
        1
    );

    movVentaSection.write(`      INFORMACIÓN  DE MOVIMIENTOS`);
    movVentaSection.drawBorder(false, true);


    const movVentaTextStyle = new Style(9, "normal", 1, 'left');
    const movVentaTextSection = new Section(
        doc,
        4,
        movVentaSection.endY + 4,
        movVentaTextStyle,
        movVentaSection.width
    );

    data.movCajas.forEach(m => movVentaTextSection.write(`${m.Tipo} - ${moment(m.FechaHora).format("DD/MM/YYYY HH:mm")} - ${m.Observacion && m.Observacion.length ? m.Observacion : "No se ingresó observación"}`))

    doc.autoPrint();
    const bloburl = doc.output("bloburl");
    printBlob(bloburl)

}


async function TraerInfoPDF(idTurno) {
    const url = idTurno ? `/api/cajas/cierreTurno/InfoPDFTL/${idTurno}` : `/api/cajas/cierreTurno/InfoPDF/`
    try {
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function numeroALetras(total) {
    try {
        const response = await fetch(`/api/cajas/numero-letras?total=${total}`);
        let data = await response.json();
        return data.desc;
    } catch (error) {
        return '';
    }
}

